<!-- 矩形图配置 -->
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'rectangle',
  inject: ["main"]
}
</script>

<style>
</style>